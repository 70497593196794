.alacrity-content {
  overflow-y: auto;
  display: grid;
  -ms-grid-columns: (minmax(0, 1fr)) [28];
  grid-template-columns: repeat(28, minmax(0, 1fr));
  width: 70%;
}

.alacrity-content__inner {
  grid-column: 4 / 26;
}
