@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400;1,500&display=swap");

$black-main: #484848;
$grey-main: #676767;
$text-yellow: rgba(242, 212, 32, 1);
$text-yellow-highlight: rgba(242, 212, 32, 0.6);

.App {
  height: calc(100% - 80px);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: $grey-main;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

a {
  text-decoration: none;
  color: $text-yellow;
}

a:hover {
  color: $text-yellow-highlight;
}

img {
  width: 100%;
}

.flex-container {
  display: inline-flex;
  height: 100%;
}
