@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400;1,500&display=swap);
html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.alacrity-header{height:80px;border-bottom:1px solid #e9e9ee;margin-bottom:-1px;display:flex;align-items:center}.alacrity-header__logo{margin-left:7.5%}.alacrity-header__logo img{padding-top:8px;height:46px;width:auto}.alacrity-header__tagline{margin-left:22px;padding-top:8px;font-weight:400;font-size:15px}

.alacrity-heading{font-weight:400;margin-bottom:10px}.alacrity-heading__accent-line{margin-left:0;margin-bottom:30px;width:50px;border:1px solid #f2d420}

.share-social i{color:#f5dd50}.share-social i:hover{color:rgba(245,221,80,0.6)}.share-social li:not(:last-child) i{margin-right:16px}.share-social ul.share-buttons{list-style:none;padding:0;margin:0}.share-social ul.share-buttons li{display:inline}.share-social ul.share-buttons li i{cursor:pointer}

.alacrity-nav{width:30%;border-right:1px solid #e9e9ee}.alacrity-nav__inner-container{height:calc(100% - 140px);margin-left:25%;padding-right:8.3333333333%;overflow-y:auto}.alacrity-nav__inner-container--main-nav .alacrity-heading{margin-top:0;padding-top:40px}.alacrity-nav__inner-container--main-nav .alacrity-heading__accent-line{margin-bottom:26px}.alacrity-nav__inner-container--main-nav ul{list-style:none;-webkit-padding-start:0;padding-inline-start:0}.alacrity-nav__inner-container--main-nav ul li{margin-bottom:18px}.alacrity-nav__inner-container--main-nav ul li a{text-decoration:none;color:#676767}.alacrity-nav__inner-container--main-nav ul li a:hover{color:#a9a9a9}.alacrity-nav__nav-footer{height:140px;margin-left:25%;margin-right:8.3333333333%;display:flex;flex-direction:column;justify-content:center;margin-top:-1px;border-top:1px solid #e9e9ee}.alacrity-nav__nav-footer .inspiration-link{padding-top:14px;padding-bottom:16px}.alacrity-nav__nav-footer .inspiration-link a{color:#676767;font-size:18px;font-weight:400}.alacrity-nav__nav-footer .inspiration-link a:hover{color:#a9a9a9}.alacrity-nav__nav-footer .share-social{padding-bottom:10px}.alacrity-nav__nav-footer .copyright{font-size:14px}

.home-view{margin:40px 0}.home-view>.post-preview{margin-bottom:40px}.home-view>.post-preview .post-preview__copy .post-preview__copy--title{font-size:40px;font-weight:300}@media (min-width: 1024px){.home-view>.post-preview{width:47.5%}}.post-preview__copy{display:flex;flex-direction:column}.post-preview__copy--title{font-size:22px;font-weight:400;margin-bottom:0;margin-top:12px}.post-preview__copy--description{font-style:italic;font-size:16px;margin-bottom:0;margin-top:12px}.post-preview__copy--read-time{font-size:15px;margin-bottom:0;margin-top:10px}.post-preview__copy--read-time .fa-stopwatch{margin-right:6px}.post-preview__copy--read-more{font-size:16px;font-weight:500;margin-bottom:0;margin-top:14px}.featured-wall{display:flex;flex-direction:column}@media (min-width: 1024px){.featured-wall{flex-direction:row;flex-wrap:wrap}.featured-wall>:nth-child(odd){margin-right:calc(5% - 4px)}}.featured-wall .post-preview{margin-bottom:40px}@media (min-width: 1024px){.featured-wall .post-preview{width:47.5%}}

.article-view{margin:40px 0}.article-view a{font-weight:500}.article-view p{line-height:1.6}@media (min-width: 1024px){.article-view .article-view__title-image{width:75%;max-width:960px}}.article-view .article-view__title{margin-bottom:14px}.article-view .article-view__read-time{margin-top:0;margin-bottom:20px}.article-view .article-view__read-time .fa-stopwatch{margin-right:6px}.article-view .share-social{margin-top:20px}

.alacrity-content{overflow-y:auto;display:grid;-ms-grid-columns:minmax(0, 1fr) [28];grid-template-columns:repeat(28, minmax(0, 1fr));width:70%}.alacrity-content__inner{grid-column:4 / 26}

.App{height:calc(100% - 80px);font-family:"Roboto", sans-serif;font-weight:400;color:#676767}h1,h2,h3,h4,h5,h6{font-weight:400}a{text-decoration:none;color:#f2d420}a:hover{color:rgba(242,212,32,0.6)}img{width:100%}.flex-container{display:inline-flex;height:100%}

